<template>
    <div class="AssessmentSummary-dialog">
        <CSBreadcrumb />
        <div v-if="blockId === 1" class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>日期</th>
                        <th>考核项</th>
                        <th>{{type === 1 ? '加' : '扣'}}分</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr :data-index="index" v-for="(grade,index) in proGradeDetailList" :key="index">
                        <td class="date-td">{{grade.date}}</td>
                        <td>{{grade.content}}</td>
                        <td>{{grade.score}}</td>
                    </tr>
                </template>
            </CSTable>
        </div>
        <div v-if="blockId === 2" class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>考核项</th>
                        <th>分值</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr :data-index="index" v-for="(grade,index) in genGradeDetailList" :key="index">
                        <td>{{grade.content}}</td>
                        <td>{{grade.score}}</td>
                    </tr>
                </template>
            </CSTable>
        </div>
        <div v-if="blockId === 3" class="result-panel">
            <CSTable>
                <template v-slot:thead>
                    <tr>
                        <th>日期</th>
                        <th>类型</th>
                        <th>考核项</th>
                        <th>详情</th>
                        <th>{{type === 1 ? '加' : '扣'}}分</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr :data-index="index" v-for="(grade,index) in autoGradeDetailList" :key="index">
                        <td>{{grade.date}}</td>
                        <td>{{grade.category}}</td>
                        <td>{{grade.content}}</td>
                        <td>
                            <span class="allow-click" v-if="grade.category !== '考勤'" :data-url="grade.refUrl" @click="openAbnormalTaskModal">
                                查看
                            </span>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <td>{{grade.score}}</td>
                    </tr>
                </template>
            </CSTable>
        </div>
        <ViewModal />
    </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb"
import ViewModal from "@/components/ViewModal";
import {
    professionalDetailsUrl,
    automationDetailsUrl,
    generalDetailsUrl, queryTicketUrl, queryInspectionUrl
} from "@/requestUrl"
import CSTable from "@/components/common/CSTable";

export default {
    components:{
        CSTable,
        CSBreadcrumb,
        ViewModal,
    },
    props: {
      id: Number,
      type: Number,
      blockId: {
          type: Number,
          default: 1,
      },
    },
    data(){
        return {
            genGradeDetailList:[],
            proGradeDetailList:[],
            autoGradeDetailList:[]
        }
    },
    created(){
        switch (this.blockId){
            case 1:
                this.getProGradeDetail();
            break;
            case 2:
                this.getGenGradeDetail();
            break;
            case 3:
                this.getAutoGradeDetail();
            break;
        }
    },
    methods:{
        getQueryString(url, queryName) {
            let reg = new RegExp("(^|&)" + queryName + "=([^&]*)(&|$)", "i");
            // 获取url中"?"符后的字符串并正则匹配
            let r = url.split("?")[1].match(reg);
            if (r != null) {
                return decodeURI(r[2]);
            }
            return null;
        },
        // 查看异常的考核项具体
        openAbnormalTaskModal(e) {
            const url = e.target.dataset.url;
            const pagePath = url.split("?")[0];
            // const type = getQueryVariable(url, 'type');
            const id = this.getQueryString(url, "id");
            let request = {};
            if (pagePath === "/workOrder/workOrder/workOrder") {
                request = {
                    url: queryTicketUrl,
                    params: {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        ticketId: id,
                    },
                };
            } else {
                request = {
                    url: queryInspectionUrl,
                    params: {
                        taskId: id,
                    },
                };
            }

            // inspectionPlanManage task
            // workOrder innerQuery
            this.$fly
                .post(request.url, {
                    ...request.params,
                })
                .then((res) => {
                    let ulTemplate = "",
                        data = res.data || res;
                    if (res.code !== 0) {
                        return;
                    }
                    if (data === null) {
                        this.$vc.toast("该数据已被删除");
                        return;
                    }

                    if (pagePath === "/workOrder/workOrder/workOrder") {
                        const wordTypeMapping = {
                            1: "公共区域清洁",
                            2: "公共区域报修",
                            3: "物品进出",
                            4: "其它",
                            5: "投诉",
                        };
                        ulTemplate = `
                                    <ul class="list-group text-left" style="font-size: 20px;">
                                        <li class="list-group-item">类型： ${
                            wordTypeMapping[data.type]
                        }</li>
                                        <li class="list-group-item">姓名：${
                            data.informantName
                        }</li>
                                        <li class="list-group-item">手机：${
                            data.informantPhone
                        }</li>
                                        <li class="list-group-item">内容：${
                            data.content
                        }</li>
                                    </ul>
                                `;
                    } else {
                        ulTemplate = `
                                    <ul class="list-group text-left" style="font-size: 20px;">
                                        <li class="list-group-item">巡检计划: ${data.planName}</li>
                                        <li class="list-group-item">巡检时间: ${data.startTime}-${data.endTime}</li>
                                        <li class="list-group-item">巡检点: ${data.pointCount}</li>
                                    </ul>
                                `;
                    }
                    this.$vc.emit(this.$route.path, "viewModal", "openViewModal", {
                        content: ulTemplate,
                        photos: null,
                        queryType: 2
                    });
                });
        },
        getAutoGradeDetail(){
            this.$fly.post(automationDetailsUrl,{
                operatorId: this.$route.query.operatorId,
                regionId: this.$route.query.regionId,
                userId: this.id,
                startDate: this.$route.query.startDate,
                endDate: this.$route.query.endDate,
                type: this.type,
            })
            .then(res=>{
                this.autoGradeDetailList = res.data || res.data.datas;
            })
        },
        getProGradeDetail(){
            this.$fly.post(professionalDetailsUrl,{
                userId: this.id,
                startDate: this.$route.query.startDate,
                endDate: this.$route.query.endDate,
                regionId: this.$vc.getCurrentRegion().communityId,
                operatorId: this.$vc.getCurrentStaffInfo().id,
            })
            .then(res=>{
                this.proGradeDetailList = res.data || res.data.datas;
            })
        },
        getGenGradeDetail(){
            this.$fly.post(generalDetailsUrl,{
                taskId: this.id,
                operatorId: this.$vc.getCurrentStaffInfo().id,
                regionId: this.$vc.getCurrentRegion().communityId,
            })
            .then(res=>{
                this.genGradeDetailList = res.data || res.data.datas;
            })
        }
    }
}
</script>

<style>

</style>
